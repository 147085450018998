import React from "react"
import { Link as XX } from "gatsby"

import "../css/link.css"

export default function Link({ children, className, to }) {
  return (
    <XX className={[`link`].concat(className || []).join(" ")} to={to}>
      {children}
    </XX>
  )
}
