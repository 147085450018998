import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"

// import '../css/tags.css';
const PrimaryBlue = "#4169E1"
const Tag = styled.li`
  display: inline-block;
  background: ${PrimaryBlue};
  color: #fff;
  padding: 5px 10px;
  margin: 2px 2px 2px 30px;
  font: normal 16px sans-serif;
  position: relative;
  cursor: default;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: swing 1s;
  -o-animation: swing 1s;
  animation: swing 1s;
  transition: all 0.2s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    background: inherit;
    height: 10px;
    border: 14px solid #fff;
    border-right-color: transparent;
    -webkit-border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
    border-radius: 10px 0 0 10px;
    left: -25px;
    top: 0;
    margin-left: -3px;
  }

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    position: absolute;
    left: -3px;
    top: 12px;
    box-shadow: inset 1px 1px 0 #ccc;
  }

  & a,
  & a:visited {
    text-decoration: none;
    color: white;
  }

  &:hover {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
`

export default function TagsList({ list = [] }) {
  return (
    <ul className="tag-list">
      {list.map((tag) => (
        <Tag key={tag}>
          <Link to={`/tags/${tag}`}>{tag}</Link>
        </Tag>
      ))}
    </ul>
  )
}
