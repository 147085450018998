import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import { FaBackward, FaForward } from "react-icons/fa"
import fly from "../images/fly.png"

import styled from "styled-components"

import Link from "../components/Link"
import TagsList from "../components/TagsList"

import "../css/blog-post.css"

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data
  const { next, prev } = pageContext

  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize.src
    : null

  const HeroImage = styled.div`
    background: url(${image}) no-repeat center center;
    background-size: cover;
    width: 480px;
    height: 268px;
    max-width: 95%;
    margin: 20px auto;

    @media (max-width: 480px) {
      width: 300px;
      height: 160px;
    }
  `
  if (post.frontmatter.title == "") {
    return <h2>Coming Soon</h2>
  } else {
    return (
      <Layout>
        <div className="blog-post-container">
          <Helmet
            title={`${post.frontmatter.title} - Antoine Prelects `}
            meta={[
              { itemprop: "name", content: post.frontmatter.title },
              { itemprop: "description", content: post.html.substring(0, 250) },
              { itemprop: "image", content: image || fly },
              { name: "keywords", content: post.frontmatter.tags || [] },

              { property: "og:title", content: post.frontmatter.title },
              { property: "og:type", content: "website" },
              {
                property: "og:url",
                content: "https://magnyficent.com" + post.frontmatter.path,
              },
              { property: "og:image:url", content: image || fly },
              { property: "og:image:width", content: "400" },
              { property: "og:image:height", content: "268" },
              {
                property: "og:description",
                content: post.html.substring(0, 250),
              },
              { property: "og:site_name", content: post.frontmatter.title },

              { property: "twitter:card", content: "summary_large_image" },
              { property: "twitter:site", content: "helloiamantoine" },
              { property: "twitter:title", content: post.frontmatter.title },
              {
                property: "twitter:description",
                content: post.html.substring(0, 250),
              },
              { property: "twitter:creator", content: "helloiamantoine" },
              { property: "twitter:image:src", content: image || fly },
            ]}
          />
          <div className="blog-post">
            <h1 className="title">{post.frontmatter.title}</h1>
            <h6 className="date">{post.frontmatter.date}</h6>

            <HeroImage />

            <div
              className="blog-post-content justify"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <TagsList list={post.frontmatter.tags || []} />
            <div className="navigation">
              {prev && (
                <Link className="link prev" to={prev.frontmatter.path}>
                  <FaBackward /> {prev.frontmatter.title}
                </Link>
              )}
              {next && (
                <Link className="link next" to={next.frontmatter.path}>
                  {next.frontmatter.title} <FaForward />
                </Link>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
        tags
        image {
          childImageSharp {
            resize(width: 480) {
              src
            }
          }
        }
      }
    }
  }
`
